<template>
  <div class="container_cont_box">
    <div class="banner-content" v-if="bannerList.length > 0">
      <div class="swiper_banner">
        <swiper class="banner" ref="mySwiper" :options="swiperOptionsBanner">
          <swiper-slide
            v-for="(item, index) in bannerList"
            :key="item.image + '_' + index"
          >
            <a :href="item.link" target="_blank">
              <img class="banner-img" :src="item.image" />
              <!-- <img class="banner-img" src="@/assets/index/banner.png"> -->
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="content_cont_box">
      <!-- 直播课堂 -->
        <div class="content-box live-content" v-if="liveModule.length > 0">
          <div class="cont_item clearfix">
            <div class="title_cont flex">
              <div class="left_cont">
                <p class="title">直播课堂</p>
              </div>
              <!-- <div
                class="more_btn cursor flex"
                @click="$router.push('/liveList')"
              >
                <span>更多</span>
                <img src="@/assets/index/right_icon.png" alt="" />
              </div> -->
            </div>
            <div class="live-list-content">
              <div class="img-show">
                <img :src="liveSelect.image" alt="" @click="getChannelNo" />
              </div>
              <ul class="live-list">
                <li
                  class="live-info"
                  :class="item.id == liveSelect.id ? 'active' : ''"
                  v-for="item in liveModule"
                  :key="item.id"
                  @click="selectLive(item)"
                >
                  <div :class="item.liveNow ? 'live-now' : 'live-date'">
                    {{ item.liveNow ? "正在直播" : item.live_date }}
                  </div>
                  <div class="live-title" @click="liveModuleImg = item.image">
                    {{ item.name }}
                    <!-- <router-link :to="'/live/' + item.channel_no">{{
                    
                  }}</router-link> -->
                  </div>
                  <!-- <router-link  :to="'/live/'+item.channel_no" class="live-title">{{item.name}}</router-link> -->

                  <div class="live-lecturer">
                    主讲人：{{ item.teacher_name }}
                  </div>
                  <!-- <router-link :to="'/live/'+item.channel_no" class="live-btn into" :class="item.liveNow? 'into': 'subscribe'">{{item.liveNow? '进入直播': '立即预约'}}</router-link> -->
                  <router-link
                    v-if="item.liveNow"
                    :to="'/live/' + item.channel_no + '/' + item.id"
                    class="live-btn into"
                    >进入直播</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      <div>
        <!-- 创新思维与创新方法 -->
        <div class="cont_item cont_box1">
          <div class="title_cont flex">
            <div class="left_cont flex">
              <p class="title">{{showText}}</p>
              <ul class="flex">
                <li
                  class="cursor"
                  v-for="item in moduleList1"
                  :key="item.id"
                  :class="modalId1 == item.id ? 'active' : ''"
                  @click="changModal(item.id,'1')"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li v-for="item in courseList1" :key="item.id" @click="goCourseDetail(item)">
                <p class="img"><img :src="item.image" alt="" /></p>
                <div class="cont_info">
                  <p class="title overflow_o">{{ item.name }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="deep_btn" v-if="siteInfo.id==73" @click="goNewUrl('https://npx.kskstudy.com')">更多优质课程</div>
        </div>
        <div class="cont_item cont_box1" v-if="moduleList2.length>0" style="margin-top:50px;">
          <div class="title_cont flex">
            <div class="left_cont flex">
              <p class="title" v-if="siteInfo.id==73">创新思维与创新方法</p>
              <p class="title" v-else>专业能力提升培训课程</p>
              <ul class="flex">
                <li
                  class="cursor"
                  v-for="item in moduleList2"
                  :key="item.id"
                  :class="modalId2 == item.id ? 'active' : ''"
                  @click="changModal(item.id,'2')"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li v-for="item in courseList2" :key="item.id" @click="goCourseDetail(item)">
                <p class="img"><img :src="item.image" alt="" /></p>
                <div class="cont_info">
                  <p class="title overflow_o">{{ item.name }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="deep_btn" v-if="siteInfo.id==73" @click="goNewUrl('https://npx.kskstudy.com')">更多优质课程</div>
        </div>
        <!-- 大师来了 -->
        <div class="cont_item cont_box2" v-if="teacherList.length>0">
          <div class="title_cont flex">
            <div class="left_cont flex" style="justify-content: space-between;">
              <p class="title">大师来了</p>
              <div v-if="type == 2" class="more_btn cursor flex" @click="$router.push('/teacherList?id=' + 446)">
                <span>更多</span>
                <img src="@/assets/index/right_icon.png" alt="" />
              </div>
              <!-- <ul style="margin-left: 16px">
                <li>国家级工作室带头人入企指导</li>
              </ul> -->
            </div>
          </div>
          <div class="teacher_cont_box">
            <div class="show_teacher_info">
              <div class="left_img"><img :src="showTeacher.avatar_34" alt="" /></div>
              <div class="right_cont_info">
                <div class="name_info">
                  <p class="name">{{showTeacher.name}}</p>
                  <p class="level_info flex">
                    <img src="@/assets/index/level_icon.png" alt="" />
                    <span>{{showTeacher.level}}</span>
                  </p>
                </div>
                <div class="text_info overflow_4">
                    {{showTeacher.introduce}}
                </div>
                <div class="tips_list" >
                  <ul>
                    <li v-for="(item,index) in showTeacher.honor" :key="index">{{item}}</li>
                  </ul>
                </div>
                <div class="course_text" v-if="showTeacher.course&&showTeacher.course.length>0">
                  <p class="tips">
                    主讲课程：<span  v-for="(item,index) in showTeacher.course" :key="index">《{{item}}》</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="teacher_list">
              <ul>
                <li v-for="item in teacherList" :key="item.id" :class="showTeacher.id==item.id?'active':''" @click="getTeacherDetail(item)">
                  <p class="img"><img :src="item.avatar_34" alt="" /></p>
                  <div class="name_cont">
                    <p class="name">{{ item.name }}</p>
                    <p class="tips overflow_3">{{ item.introduce }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="type == 1" class="deep_btn"  @click="goNewUrl('https://npx.kskstudy.com/teacherList')">更多国家级荣誉师资</div>
          </div>
        </div>
        <!-- 广告条 -->
       <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#"  target="_blank" v-if="siteInfo.id==73">
         <div class="line_img">
            <img src="@/assets/index/line_img1.png" alt="" />
          </div>
       </a>
        <!-- 企业研学 -->
        <div class="cont_item cont_box3" v-for="item in contDataInfo" :key="item.id">
          <div class="title_cont flex">
            <div class="left_cont">
              <p class="title">{{item.name}}</p>
            </div>
            <div class="more_btn cursor flex" @click="$router.push('/activeList/'+item.id)">
              <span>更多</span>
              <img src="@/assets/index/right_icon.png" alt="" />
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li class="active-info" v-for="list in item.list" :key="list.id" @click="toActiveInfo(list)">
                <p class="img"><img :src="list.image" alt="" /></p>
                <div class="cont_info">
                  <p class="title">{{list.name}}</p>
                  <div class="text_info">
                    <p class="name overflow_o">{{list.desc}}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 人物故事 -->
        <div v-if="siteInfo.id == 110 && characterStoryData.length > 0" class="cont_item cont_box3">
          <div class="title_cont flex">
            <div class="left_cont">
              <p class="title">人物故事</p>
            </div>
            <div class="more_btn cursor flex" @click="$router.push('/characterStory')">
              <span>更多</span>
              <img src="@/assets/index/right_icon.png" alt="" />
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li class="active-info" v-for="list in characterStoryData" :key="list.id" @click="toActiveStoryInfo(list)">
                <p class="img"><img :src="list.image" alt="" /></p>
                <div class="cont_info">
                  <p class="title">{{list.name}}</p>
                  <div class="text_info">
                    <p class="name overflow_o">{{list.intro}}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 创新成果展示 -->
        <!-- <div class="cont_item cont_box4 cont_box3">
          <div class="title_cont flex">
            <div class="left_cont">
              <p class="title">创新成果展示</p>
            </div>
            <div class="more_btn flex">
              <span>更多</span>
              <img src="@/assets/index/right_icon.png" alt="" />
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li v-for="item in 3" :key="item.id">
                <p class="img"><img src="" alt="" /></p>
                <div class="cont_info">
                  <p class="title">专利申请与奖项申报</p>
                  <div class="text_info">
                    <p class="name">服务基层：服务一线 服务职工</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <!-- 广告条 -->
      <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" v-if="siteInfo.id==73">
        <div class="line_img">
          <img src="@/assets/index/line_img2.png" alt="" />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      bannerList: [],
      moduleList:[],//创新思维与创新方法 分类
      moduleList1:[],
      moduleList2:[],
      modalId1:[],
      modalId2:'',
      courseList1:[],//创新思维与创新方法
      courseList2:[],//创新思维与创新方法
      teacherList:[],//大师来了
      showTeacher:'',//当前显示讲师
      contDataInfo:[],
      swiperOptionsBanner: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      siteInfo:'',
      showText:'安全管理培训',
      liveModule: [], //直播模块
      liveSelect: "", //直播模块选中
      type: 1, // 1 劳动管理 2 黄河工匠学院
      characterStoryData: [],
      websiteStateData: {} //人物故事
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    if(window.location.host=='hhgjxy.kskstudy.com'){
        this.showText = '工匠人才培育'
        this.type = 2
    }else{
        this.showText = '安全管理培训'
        this.type = 1
    }
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    this.websiteStateData = JSON.parse(localStorage.getItem('websiteState'))
    if (this.siteInfo.id == 110) {
      this.characterStoryList()
      
    }
    this.getBanner();
    this.getLliveModule(); //获取直播课堂
    this.getModule(); //获取首页模块
  },
  methods: {
    // 黄河工匠人物故事
    characterStoryList() {
      this.$axios.post(`/v1/hh/characterStoryList`,{ page: 1, per_page: 3 }).then((res) => {
        this.characterStoryData = res.data.data;
      });
    },
    //选择直播
    selectLive(data) {
      this.liveSelect = data;
    },
    getChannelNo() {
      this.$router.push(
        "/live/" + this.liveSelect.channel_no + "/" + this.liveSelect.id
      );
    },
    changModal(item,num){
      if(num==1){
        this.modalId1=item
      }else{
        this.modalId2 = item
      }
        this.getCourseList(item,num)
    },
    goCourseDetail(item){
      if (this.websiteStateData.is_no_login_look == 1) {
        this.$router.push('/recordedInfo/'+item.data_id)
      } else {
        this.$router.push('/newRecordedInfo/'+item.data_id)
      }
    },
    goNewUrl(url){
        window.open(url)
    },
    //获取直播课堂
    getLliveModule() {
      this.$axios.post(`/v1/home/liveModule`).then((res) => {
        let nowDta = Date.now() / 1000;

        for (let i = 0; i < res.data.length; i++) {
          if (nowDta > res.data[i].live_time && nowDta < res.data[i].end_time) {
            res.data[i].liveNow = true;
          } else {
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      });
    },
    // 获取banner图
    getBanner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "1",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    //跳转活动文章详情
    toActiveInfo(data){
      if (data.type === 0) {
        this.$router.push("/activeInfo/" + data.data_id);
      } else if (data.type === 1) {
        location.href = data.m_link;
      }
    },
    // 人物故事
    toActiveStoryInfo(data){
      if(!data.link){
          this.$router.push('/characterStoryInfo/'+data.id);
        }else{
          window.open(data.link);
        }
    },
    //获取首页模块
    getModule() {
      this.$axios.post(`/v1/home/module`).then((res) => {
        if(res.code==0){
            let contBox3 = []
            res.data.forEach(el=>{
                if(el.type==1){
                    this.moduleList.push(el)
                }
                if(el.type==5){
                    this.getTeacher(el.id)
                }
                if(el.type==9){
                    contBox3.push(el)
                }
            })
            if(this.moduleList.length>6){
              this.moduleList1 = this.moduleList.slice(0,6)
              this.moduleList2 = this.moduleList.slice(6,this.moduleList.length)
              this.modalId2 = this.moduleList2[0].id
              this.getCourseList(this.modalId2,'2')
            }else{
              this.moduleList1 = this.moduleList
            }
            this.modalId1 = this.moduleList1[0].id
          
            this.getCourseList(this.modalId1,'1')
            
             this.getModuleData(contBox3);
        }
       
      });
    },
    // 获取 创新思维与创新方法 列表
    getCourseList(id,num){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
            if(num==1){
              this.courseList1 = res.data.data
            }else{
              this.courseList2 = res.data.data
            }
        })
    },
    // 获取 大师来了 列表
    getTeacher(id){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
            let data = []
            if(res.data.length>4){
                data = res.data.splice(0,4)
            }else{
                data = res.data
            }
            data.forEach(el=>{
              el.course=[]
              el.honor=[]
            })
            this.teacherList = data
            this.getTeacherDetail(this.teacherList[0])
        })
    },
    getTeacherDetail(item){
      this.showTeacher = item
      this.$axios.post(`/v1/home/teacherTag`,{teacher_id:item.teacher_id}).then(res=>{
        if(res.code==0){
          this.showTeacher.course = res.data.course
          this.showTeacher.honor = res.data.honor
          console.log(this.showTeacher)
        }
      })
      
    },
    //获取各模块数据
    async getModuleData(moduleData) {
      this.contDataInfo = moduleData;

    //   if (this.contDataInfo <= 0) {
    //     this.loadState = false;
    //     setTimeout(() => {
    //       this.loadState1 = false;
    //     }, 1000);
    //   }
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.contDataInfo.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
  },
};
</script>
<style scoped lang="scss">
// banner图
.banner-content {
  width: 1200px;
  margin: 20px auto 0;
  height: 440px;
  img {
    width: 100%;
    height:440px;
    border-radius: 5px;
  }
}
.content_cont_box {
  // margin-top: 76px;
  margin-top: 50px;
  // 直播
  .live-content {
    width: 100%;
    padding: 68px 0;
  }
  .live-list-content {
    .live-list {
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
      border-radius: 0px 6px 6px 0px;
      float: left;
      overflow-y: auto;
    }
    .live-info {
      padding: 19px 20px 24px 15px;
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        width: 2px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.06);
        position: absolute;
        bottom: 0;
        left: 29px;
      }
      &.active {
        background: #f5f5f5;
      }
    }
    .live-now {
      height: 30px;
      line-height: 30px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: 800;
      color: #f5a623;
      background: url(~@/assets/live-now-ico.png) no-repeat;
      background-size: 30px;
      background-position: left;
      margin-bottom: 7px;
    }
    .live-date {
      height: 20px;
      line-height: 20px;
      padding-left: 34px;
      background: url(~@/assets/clock-ico.png) no-repeat;
      background-size: 20px;
      background-position: 6px center;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      margin-bottom: 12px;
    }
    .live-title {
      padding-left: 40px;
      font-size: 16px;
      color: #333;
      margin-bottom: 25px;
      a {
        color: #333;
      }
    }
    .live-lecturer {
      padding-left: 40px;
      font-size: 12px;
      color: #666;
    }
    .live-btn {
      width: 94px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      box-shadow: 0px 2px 4px 0px rgba(101, 67, 11, 0.2);
      border-radius: 6px;
      position: absolute;
      right: 20px;
      cursor: pointer;
      &.into {
        background: #ffc30b;
        color: #333;
      }
      &.subscribe {
        background: #254ed4;
        color: #fff;
      }
    }
  }
  margin-top: 50px;
  .cont_item {
    width: 1200px;
    margin: 0 auto;
    .title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 34px;
      .title {
        min-width: 140px;
        font-size: 30px;
        color: #333;
        display: flex;
        align-items: flex-end;
        .title-text {
          position: relative;
          .text {
            position: relative;
            z-index: 1;
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 14px;
            border-radius: 7px;
            background-image: linear-gradient(90deg, #ffc30b, #fff7b9);
            position: absolute;
            bottom: 0;
            z-index: 0;
          }
        }
      }
      .sub-title {
        font-size: 16px;
        margin-left: 18px;
      }
      .more {
        height: 19.88px;
        padding-right: 22px;
        font-size: 14px;
        color: #999;
        background: url(~@/assets/arrows-right.png) no-repeat;
        background-size: 19.88px;
        background-position: right;
        cursor: pointer;
      }
    }
    .title_cont {
      width: 1200px;
      margin: 0 auto 30px;
      justify-content: space-between;
      .left_cont {
        align-items: flex-end;
        flex: 1;
        p.title {
          font-size: 28px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        ul {
          flex: 1;
          margin-left: 44px;
          //   flex-wrap: wrap;
          li {
            margin-right: 40px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #666666;
            position: relative;
          }
          li.active {
            color: #0065df;
          }
          li.active::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            width: 20px;
            height: 2px;
            border-radius: 2px;
            background: #0065df;
          }
          li:last-child {
            margin-right: 0;
          }
        }
      }
      .more_btn {
        width: 84px;
        height: 32px;
        background: #ffffff;
        border-radius: 275px 275px 275px 275px;
        opacity: 1;
        border: 1px solid #d8d8d8;
        justify-content: center;
        span {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .cont_item {
    .title_cont {
      width: 1200px;
      margin: 0 auto 30px;
      justify-content: space-between;
      .left_cont {
        align-items: flex-end;
        flex: 1;
        p.title {
          font-size: 28px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        ul {
          flex: 1;
          margin-left: 44px;
        //   flex-wrap: wrap;
          li {
            margin-right: 40px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #666666;
            position: relative;
          }
          li.active {
            color: #0065df;
          }
          li.active::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            width: 20px;
            height: 2px;
            border-radius: 2px;
            background: #0065df;
          }
          li:last-child {
            margin-right: 0;
          }
        }
      }
      .more_btn {
        width: 84px;
        height: 32px;
        background: #ffffff;
        border-radius: 275px 275px 275px 275px;
        opacity: 1;
        border: 1px solid #d8d8d8;
        justify-content: center;
        span {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  // 创新思维与创新方法
  .cont_box1 {
    width: 1200px;
    margin: 0 auto;
    .course_list {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 278px;
          height: 230px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #ebebeb;
          margin-right: 26px;
          margin-bottom: 24px;
          p.img {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px 8px 0 0;
            }
          }
          .cont_info {
            box-sizing: border-box;
            padding: 20px 16px 0;
            p.title {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .teacher_name {
              margin-top: 10px;
              p {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              p.name {
                margin-right: 20px;
              }
            }
          }
        }
        li:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
    .deep_btn {
      width: 140px;
      margin: 32px auto 48px;
      height: 40px;
      background: linear-gradient(90deg, #0065df 0%, #27a5ff 99%);
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }

  // 大师来了
  .cont_box2 {
    .teacher_cont_box {
      background: url("~@/assets/index/bgm.png") no-repeat;
      background-size: 100% 444px;
      .show_teacher_info,
      .teacher_list {
        width: 1200px;
        margin: 0 auto;
      }
      .show_teacher_info {
        display: flex;
        padding-top: 51px;
        .left_img {
          width: 226px;
          height: 286px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .right_cont_info {
          flex: 1;
          margin-left: 40px;
          .name_info {
            display: flex;
            p.name {
              font-size: 26px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #3d3d3d;
            }
            .level_info {
              height: 28px;
              background: #f9f1e6;
              border-radius: 238px 238px 238px 238px;
              margin-left: 25px;
              padding-right: 12px;
              img {
                width: 36px;
                margin-left: -18px;
              }
              span {
                line-height: 28px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #e79e0c;
              }
            }
          }
          .text_info {
            margin-top: 21px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .tips_list {
            margin-top: 20px;
            ul {
              display: flex;
              align-items: center;
              li {
                margin-right: 16px;
                height: 28px;
                border-radius: 28px;
                opacity: 0.8;
                border: 1px solid #0065df;
                line-height: 28px;
                padding: 0 12px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #0065df;
              }
            }
          }
          .course_text {
            margin-top: 26px;
            p {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .teacher_list {
        margin-top: 43px;
        ul {
          display: flex;
          li {
            width: 285px;
            height: 132px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 2px solid #ebebeb;
            margin-right: 20px;
            box-sizing: border-box;
            padding: 13px 20px;
            display: flex;
            p.img {
              width: 84px;
              height: 106px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                opacity: .7;
              }
            }
            .name_cont {
              flex: 1;
              margin-left: 20px;
              p.name {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #3d3d3d;
                line-height: 36px;
              }
              p.tips {
                font-size: 13px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
              }
            }
          }
          li.active{
            border: 2px solid #79B1F6;
            p.img{
                img{
                    opacity: 1;
                }
            }
          }
          li:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      .deep_btn {
        width: 182px;
        height: 40px;
        background: linear-gradient(90deg, #0065df 0%, #27a5ff 99%);
        border-radius: 4px 4px 4px 4px;
        margin: 40px auto 86px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  // 企业研学
  .cont_box3 {
    width: 1200px;
    margin: 68px auto 60px;
    .course_list {
      ul {
        display: flex;
        flex-wrap: wrap;
        .active-info{
          background-color: #FFF;
          box-shadow: 0px 0px 14px 1px rgba(0,12,40,0.14);
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.2s ease-out;
          margin-right: 24px;
          &:hover {
            position: relative;
            transform: translateY(-6px);
          }
        }
        .active-info:nth-child(3n){
          margin-right: 0;
        }
        li {
          width: 374px;
          height: 338px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #ebebeb;
          margin-right: 35px;
          p.img {
            width: 100%;
            height: 214px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .cont_info {
            padding: 20px;
            p.title {
              height: 44px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .text_info {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #666666;
              margin-top: 14px;
            }
          }
        }
        li:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // 创新成果展示
  .cont_box4 {
    margin-top: 80px;
    margin-bottom: 91px;
  }

  // 广告条
  div.line_img {
    img {
      display: block;
      width: 100%;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.overflow_o {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflow_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.overflow_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.banner::v-deep .swiper-pagination-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 1);
    // background: #fff;
    border-radius: 5px;
    opacity: 0.6;
    margin: 0 3px;
    // display: flex;
    // justify-content: flex-end;
}

.banner ::v-deep .swiper-pagination-bullets {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    bottom: 12px;
    box-sizing: border-box;
}

.banner::v-deep .swiper-pagination-bullet-active {
    width: 20px;
    background: rgba(255,255,255,0.8);
    // opacity: 1;
}
</style>
